/* You can add global styles to this file, and also import other style files */
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ng-zorro-antd/style/index.min.css";


/* Import base styles */
@import "~ng-zorro-antd/button/style/index.min.css";
/* Import one component's styles */
@import "~leaflet/dist/leaflet.css";

body {
  margin: 0;
  font-family: "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #342E49;
  text-align: left;
  background-color: #F7F7F8;
}

a {
  color: #8741ff;
  background-color: transparent;
  cursor: pointer;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #8741ff;
}


.dropdown-toggle::after {
  align-self: center;
}



.d-flex.align-items-center > .form-control-position {
  top: auto !important;
}


.gap-05 {
  gap: 0.5rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}
/* ::ng-deep .ng-select {
  border: 1px  #e4e4e4 !important;
  border-radius: 0.5rem;
  opacity: 1
} */

::ng-deep .ng-select .ng-select-container {
  border: 1px solid #e4e4e4 !important;
  border-radius: 0.5rem;
  opacity: 1;
}

